<template>
  <div>
    <ejs-dialog
      ref="findVisitPopup"
      :header="`내장객 찾기 [${findVIsitSearch.bsnDate}]`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1037"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onFindVisitPopupClosed"
    >
      <div class="window findVisitor">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field reservationName">
                    <ul class="content">
                      <li class="item">
                        <input-text
                          id="visitFindNameTextBox"
                          placeholder="내장객/락카/카트/캐디명"
                          v-model="findVIsitSearch.resveVisitName"
                          :tabindex="visitFindNameTextBoxTabIndex"
                          @keydown.enter="onVisitFindNameTextBoxKeyDownEnter"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      v-on:click.native="getFindVisitList"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">내장객 목록</div>
                      <div class="header-caption">
                        [{{ numberWithCommas(findVisitListNumber) }} 건]
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="findVisitGrid"
                        id="findVisitGrid"
                        :allowResizing="true"
                        :dataSource="findVisitList"
                        :selectionSettings="selectionSettings"
                        :allowExcelExport="true"
                        :columns="findVisitGridColumn"
                        :provides="grid"
                        :isSelectedRowRetain="false"
                        :isInPopup="true"
                        @actionComplete="onFindVisitGridActionComplete"
                        @queryCellInfo="findVisitGridQueryCellInfo"
                        @rowSelected="findVisitGridRecordClicked"
                        @onGridDialogDoubleClickedOrEnterKeyed="onFindVisitGridDoubleClick"
                        @dataBound="onFindVisitGridDataBound"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="confirmFindVisitPopup()"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeFindVisitPopup()">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { Resize, ForeignKey, Sort, Filter } from "@syncfusion/ej2-vue-grids";

import {commonCodesGetComName, commonCodesGetSortNo} from "@/utils/commonCodes";
import { getFormattedDate } from "@/utils/date";
import { numberWithCommas } from "@/utils/number";
import { getFindVisitList } from "@/api/frontManagement";
import { orderBy as _orderBy } from "lodash";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputText from "@/components/common/text/InputText";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "findVisitPopup",
  mixins: [commonMixin],
  components: {
    InputText,
    EjsGridWrapper,
    ErpButton,
  },
  created() {
    this.resveDate = getFormattedDate(new Date());
  },
  data() {
    return {
      grid: [Resize, ForeignKey, Sort, Filter],
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      visitFindNameTextBoxTabIndex: -1,
      findVisitList: [],
      findVisitListNumber: 0,
      findVIsitSearch: {
        bsnDate: null, // 영업일자
        courseCode: null, // 코스
        partDiv: null, // 부구분
        resveVisitName: null, // 예약자/내장객명
        resveVisitFlag: null, // 내장하지 않은 예약자만 출력할 시 false, 내장하지 않은 예약자와 예약한 내장객 다 출력할 시 true
      },
      isGetTeamData: false,
      selectedRowData: null,
      findVisitGridColumn: [
        {
          field: "timeId",
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: "partDiv",
          headerText: "부",
          width: 70,
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "PART_DIV",
          type: "string",
        },
        {
          field: "startTime",
          headerText: "시간",
          width: 70,
          textAlign: "center",
          type: "string",
        },
        {
          field: "startCourse",
          headerText: "코스",
          width: 80,
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "COURSE_CODE",
          type: "string",
        },
        {
          field: "holeDiv",
          headerText: "홀",
          width: 70,
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "HOLE_DIV",
          type: "string",
        },
        {
          field: "resveName",
          headerText: "예약자",
          width: 90,
          textAlign: "left",
          type: "string",
        },
        {
          field: "grpName",
          headerText: "단체명",
          width: 160,
          textAlign: "left",
          type: "string",
        },
        {
          field: "visitName",
          headerText: "내장객",
          width: 90,
          textAlign: "left",
          type: "string",
        },
        {
          field: "lockerNo",
          headerText: "락카",
          width: 70,
          textAlign: "left",
          type: "string",
        },
        {
          field: "caddieName",
          headerText: "캐디명",
          width: 90,
          textAlign: "left",
          type: "string",
        },
        {
          field: "chkinTime",
          headerText: "C/I",
          width: 70,
          textAlign: "left",
          type: "string",
        },
        {
          field: "chkoutTime",
          headerText: "C/O",
          width: 70,
          textAlign: "left",
          type: "string",
        },
      ],
    };
  },
  methods: {
    numberWithCommas,
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    onFindVisitPopupClosed() {
      this.$emit("popupClosed");
    },
    async showFindVisitPopup(findVisitObject) {
      this.$refs.findVisitPopup.show();
      this.isGetTeamData = !!findVisitObject.isGetTeamData;
      this.findVIsitSearch.bsnDate = findVisitObject.bsnDate;
      this.findVIsitSearch.courseCode = findVisitObject.courseCode;
      this.findVIsitSearch.partDiv = findVisitObject.partDiv;
      this.findVIsitSearch.resveVisitName = findVisitObject.resveVisitName;
      this.findVIsitSearch.resveVisitFlag = findVisitObject.resveVisitFlag;
      Object.keys(this.findVIsitSearch).forEach((data) => {
        this.findVIsitSearch[data] =
          this.findVIsitSearch[data] === "" ? null : this.findVIsitSearch[data];
      });
      if (findVisitObject.resveVisitName) {
        await this.getFindVisitList();
      }
    },
    closeFindVisitPopup() {
      this.$refs.findVisitPopup.hide();
    },
    confirmFindVisitPopup() {
      if (this.selectedRowData !== null) {
        this.$emit("popupClosed", {
          selectRowData: this.selectedRowData,
          teamData: this.isGetTeamData
              ? this.findVisitList
                  .filter(item => item.chkinId === this.selectedRowData.chkinId)
              : null,
        });
        // this.$refs.findVisitPopup.hide();
      }
    },
    findVisitGridQueryCellInfo(args) {
      const {
        cell,
        data
      } = args;
      if (data.isPartisionRow) {
        cell.style.borderTop = '1px solid #000';
      }
    },
    findVisitGridRecordClicked(args) {
      this.selectedRowData = args.data;
    },
    async getFindVisitList() {
      // 예약자명, 내장객명 조회 API 호출
      let beforeChkinId = null;
      const response = await getFindVisitList(
        this.findVIsitSearch.bsnDate,
        this.findVIsitSearch.courseCode,
        this.findVIsitSearch.partDiv,
        this.findVIsitSearch.resveVisitName,
        this.findVIsitSearch.resveVisitFlag
      );
      this.findVisitList = _orderBy(response.value.findVisitLists?.map(item => ({
        ...item,
        courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", item.startCourse),
      })), ["startTime", "courseCodeSortNo", "partDiv", "visitSno"])?.map(item => {
          if (beforeChkinId !== item.chkinId) {
            item.isPartisionRow = !!beforeChkinId;
            beforeChkinId = item.chkinId;
          }
          return item;
        });
      this.findVisitListNumber = this.findVisitList.length;
    },
    onFindVisitGridActionComplete(args) {
      if (args.requestType === "refresh") {
        const visitName = this.findVIsitSearch.resveVisitName;
        if (visitName) {
          const currentViewRecords = this.$refs.findVisitGrid.getBatchCurrentViewRecords();
          const findIndex = currentViewRecords.findIndex(item => item.visitName === visitName);
          this.$refs.findVisitGrid.selectRow(findIndex);
        }
      } else if (args.requestType === "filtering") {
        this.findVisitListNumber = args.rows.length;
      }
    },
    onFindVisitGridDoubleClick() {
      this.confirmFindVisitPopup();
    },
    async onVisitFindNameTextBoxKeyDownEnter() {
      await this.getFindVisitList();
    },
    onFindVisitGridDataBound() {
      if (this.findVisitList.length > 0) {
        this.visitFindNameTextBoxTabIndex = -1;
        this.$refs.findVisitGrid.selectRow(0);
      } else {
        this.visitFindNameTextBoxTabIndex = 0;
        document.getElementById("visitFindNameTextBox").select();
      }
    },
  },
};
</script>
