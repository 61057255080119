<template>
  <div>
    <ejs-dialog
      ref="caddieSelectPopup"
      header="캐디 검색"
      :animationSettings="animationSettings"
      :close="dialogClose"
      width="960"
      height="800"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
    >
      <div class="window caddieSelect">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field caddieNameNumber">
                    <div class="title">캐디명/캐디번호</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                          ref="caddieNameNoTextBox"
                          id="caddieNameNoTextBox"
                          v-model="searchConditions.caddieNameNo"
                          @focus="onSearchValueClear"
                          @keydown.native="onSearchValueKeyDown"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                value="true"
                                v-model="searchConditions.retireCheck"
                                :tabindex="-1"
                              />
                              <i></i>
                              <div class="label">퇴사제외</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                    :tabindex="-1"
                    button-div="GET"
                    :is-shortcut-button="true"
                    @click.native="onViewCaddieSelectInfo"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="caddieSelectGrid"
                      :dataSource="caddieList"
                      v-bind="caddieSelectedGridOptions"
                      @queryCellInfo="queryCellInfo"
                      @rowSelected="rowSelected"
                      @onGridDialogDoubleClickedOrEnterKeyed="recordDoubleClick"
                      @dataBound="onCaddieSelectGridDataBound"
                    />
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section
                  class="article-section section-02"
                  v-show="memberLinkCardFlag"
                >
                  <div class="section-body">
                    <div class="body-card">
                      <ul class="box">
                        <li
                          class="card"
                          @click="onClickCaddieLink(caddieSelect)"
                        >
                          <div class="photo">
                            {{ caddieSelect.imagePath }}
                            <div
                              class="item"
                              v-if="caddieSelect.imagePath != null"
                              :style="{
                                backgroundImage:
                                  'url(' + caddieSelect.imagePath + ')',
                              }"
                            ></div>
                          </div>
                          <ul class="content">
                            <li class="item">
                              <strong>{{ caddieSelect.caddieName }}</strong> ({{
                                caddieSelect.caddieNo
                              }})
                            </li>
                            <li class="item">
                              {{
                                caddieSelect.caddieEmployDiv === "EMPLOY"
                                  ? "재직"
                                  : "퇴직"
                              }}
                            </li>
                            <li class="item">
                              {{ caddieSelect.entryDate }} ~
                              {{
                                !caddieSelect.retireDate
                                  ? "현재"
                                  : caddieSelect.retireDate
                              }}
                            </li>
                            <li class="item">
                              {{ caddieSelect.caddieFeeDiv }} 만원
                            </li>
                            <li class="item">
                              {{ getFormattedPhoneNumber(caddieSelect.hpNo) }}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onConfirmClicked"> 확인 </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closePopup"> 닫기 </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>
<script>
import numberTemplate from "@/views/common/template/GridNumberTemplate";
import { Edit, Resize, ForeignKey } from "@syncfusion/ej2-vue-grids";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { getCaddieInfoList } from "@/api/caddieInfoRegistration";
import { gridUtilGetTelColumnAccess } from "@/utils/gridUtil";
import { getFormattedPhoneNumber } from "@/utils/string";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "caddieSelectPopup",
  components: { InputText, EjsGridWrapper, ErpButton },
  mixinis: [confirmDialogMixin],
  data() {
    return {
      contactTelValueAccess: gridUtilGetTelColumnAccess,
      numberTemplate() {
        return {
          template: numberTemplate,
        };
      },
      caddieSelect: {},
      animationSettings: { effect: "None" },
      caddieList: [],
      field: null, // field가 textField 인경우, ejs-textbox쪽의 팝업에 데이터 셋팅. field가 gridField인경우, ejs-grid쪽의 팝업에 데이터 셋팅
      rowIndex: 0,
      memberLinkCardFlag: false,
      caddieEmployDivDataSource: commonCodesGetCommonCode("CADDIE_EMPLOY_DIV"),
      searchConditions: {
        caddieNameNo: null,
        retireCheck: true,
      },
    };
  },
  computed: {
    caddieSelectedGridOptions() {
      return {
        provides: [Edit, Resize, ForeignKey],
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        pageSettings: { pageSize: 15 },
        isInPopup: true,
        columns: [
          {
            field: "caddieId",
            allowEditing: false,
            visible: false,
            isPrimaryKey: true,
            type: "number",
          },
          {
            field: "caddieName",
            allowEditing: false,
            headerText: "캐디명",
            minWidth: 16,
            width: 90,
            textAlign: "left",
            type: "string",
          },
          {
            field: "caddieNo",
            allowEditing: false,
            headerText: "캐디번호",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            type: "string",
          },
          {
            field: "caddieEmployDiv",
            allowEditing: false,
            headerText: "재직구분",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            editType: "dropdownedit",
            isCommonCodeField: true,
            dataSource: this.caddieEmployDivDataSource,
          },
          {
            field: "entryDate",
            allowEditing: false,
            headerText: "입사일자",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            type: "string",
            isDateType: true,
          },
          {
            field: "retireDate",
            allowEditing: false,
            headerText: "퇴사일자",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            type: "string",
            isDateType: true,
          },
          {
            field: "hpNo",
            allowEditing: false,
            headerText: "핸드폰",
            minWidth: 16,
            width: 120,
            textAlign: "center",
            type: "string",
            valueAccessor: this.contactTelValueAccess,
          },
          {
            field: "caddieFeeDiv",
            allowEditing: false,
            headerText: "캐디피구분",
            minWidth: 16,
            width: 120,
            textAlign: "left",
            type: "string",
          },
          {
            field: "appnCart",
            allowEditing: false,
            headerText: "카트번호",
            minWidth: 16,
            width: 90,
            textAlign: "left",
            type: "string",
          },
        ],
      };
    },
  },
  methods: {
    getFormattedPhoneNumber,
    showPopup(caddieName, field, rowIndex) {
      this.field = field;
      this.rowIndex = rowIndex;
      this.searchConditions.caddieNameNo = caddieName;
      this.$refs.caddieSelectPopup.show();

      if (caddieName) {
        this.getCaddieInfoList();
      }
    },
    closePopup() {
      this.$refs.caddieSelectPopup.hide();
      this.$emit("popupConfirmed");
    },
    dialogClose() {
      this.$emit("popupClosed");
    },
    queryCellInfo(args) {
      if (args.data.caddieEmployDiv === "RETIRE") {
        // 퇴사자인 경우 컬러 표시
        if (
          args.column.field === "retireDate" ||
          args.column.field === "caddieNo"
        ) {
          args.cell.style.color = "red";
        }
      }
      if (args.data.caddieFeeDiv && args.column.field === "caddieFeeDiv") {
        args.cell.innerHTML = args.cell.innerHTML + "만원";
      }
    },
    rowSelected(args) {
      this.caddieSelect = args.data;
      this.caddieSelect.hpNo = args.data.hpNo
        ? args.data.hpNo.slice(0, 3) +
          "-" +
          args.data.hpNo.slice(3, 7) +
          "-" +
          args.data.hpNo.slice(7)
        : null;
      this.memberLinkCardFlag = true;
    },
    recordDoubleClick() {
      this.caddieSelect = this.$refs[
        "caddieSelectGrid"
      ].getSelectedRecords()[0];
      this.onConfirmClicked();
    },
    onSearchValueClear() {
      this.searchConditions.caddieNameNo = null;
    },
    onSearchValueKeyDown() {
      if (event.key === "Enter") {
        this.getCaddieInfoList();
      }
    },
    onConfirmClicked() {
      if (this.caddieSelect.caddieName) {
        this.$emit("popupConfirmed", {
          field: this.field,
          selectedRowData: this.caddieSelect,
          rowIndex: this.rowIndex,
        });
      } else {
        this.errorToast("회원을 선택해 주십시오");
      }
    },
    onClickCaddieLink(args) {
      this.caddieSelect = args;
    },
    onViewCaddieSelectInfo() {
      this.memberLinkCardFlag = false;
      this.caddieSelect = {}; // 검색 시 선택 데이터 초기화
      this.getCaddieInfoList();
    },
    getCaddieInfoList() {
      getCaddieInfoList(this.searchConditions.caddieNameNo)
        .then((response) => {
          if (this.searchConditions.retireCheck) {
            this.caddieList = response.value.caddieMstInfoList.filter(
              (item) => !item.retireDate
            );
          } else {
            this.caddieList = response.value.caddieMstInfoList;
          }
        })
        .catch((error) => {
          console.error("getCaddieInfoVacation.err ===>", error);
        });
    },
    onCaddieSelectGridDataBound() {
      if (this.caddieList.length > 0) {
        this.$refs.caddieSelectGrid.selectRow(0);
      } else {
        document.getElementById("caddieNameNoTextBox").select();
      }
    },
  },
};
</script>
