<template>
  <div>
    <ejs-dialog
        header="VOC 상세"
        width="800"
        height="800"
        :animationSettings="{ effect: 'None' }"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :cssClass="$t('className.popup.productCodePopupModify')"
        :close="closePopup"
    >
      <div class="window caddieVocInfo">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <ReportView ref="reportViewComponent" :isPopup="true"/>
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">VOC 상세</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0101">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">작성자</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field writngDate">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      작성일자
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ vocInfo.writngDate }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field wrterName">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      캐디명
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ vocInfo.wrterName }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section
                          class="article-section section-0102"
                          v-for="(item, index) in vocInfo.visitors" :key="index"
                      >
                        <div class="section-header" v-if="index === 0">
                          <div class="header-left">
                            <div class="header-title">고객의 말씀</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field visitName">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      내장객
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item">
                                          {{ item.name }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li
                                      class="field requestContent"
                                      v-for="(section, idx) in getWriteData(item.id)"
                                      :key="idx"
                                  >
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      {{ section.vocSectionName }}
                                    </div>
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group vocCode">
                                          <div class="form">
                                            <ejs-dropdownlist
                                                ref="vocCode"
                                                v-model="section.vocCode"
                                                :dataSource="vocCodeOptions"
                                                :allowFiltering="false"
                                                :fields="fields"
                                                cssClass="body-data-dropdown"
                                                @change="onChangeVocContent(section.vocId, item.id, section.sortNo)"
                                            />
                                          </div>
                                        </li>
                                        <li class="item form-group vocContents">
                                          <div class="form">
                                            <div
                                                class="delete"
                                                v-if="section.vocId"
                                                @click="onDeleteClick(section.vocId)"
                                            />
                                            <input-textarea
                                                ref="vocContents"
                                                placeholder="내용을 입력하세요."
                                                v-model="section.vocContents"
                                                :rows="3"
                                                :resize="false"
                                                @change="onChangeVocContent(section.vocId, item.id, section.sortNo)"
                                            />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button" style="float: left">
            <li class="print">
              <erp-button
                  buttonDiv="PRINT"
                  :is-shortcut-button="true"
                  :isIconCustom="true"
                  :ignore="isPopupOpened"
                  @click.native="onPrintClick"
              >
                인쇄
              </erp-button>
            </li>
          </ul>
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  :is-shortcut-button="true"
                  :isIconCustom="true"
                  :ignore="isPopupOpened"
                  @click.native="onSaveClick"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closePopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <caddie-select-popup
        ref="caddieSelectPopup"
        v-if="isCaddieSelectPopupOpen"
        @popupClosed="closeCaddieSelectPopup"
        @popupConfirmed="caddieSelectPopupConfirmed"
    />
    <find-visit-popup
        ref="findVisitPopup"
        v-if="isFindVisitPopupOpen"
        @popupClosed="onFindVisitPopupClosed"
    />
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import moment from 'moment';
import commonMixin from '@/views/layout/mixin/commonMixin';
import {DATE_FORMAT_YYYY_MM_DD, getFormattedDate} from '@/utils/date';
import {commonCodeGetComCodeDefaultValue, commonCodesGetCommonCode, commonCodesGetComName} from '@/utils/commonCodes';
import {formPreventEnterEvent, validateFormRefs} from '@/utils/formUtil';
import InputTextarea from '@/components/common/text/InputTextarea';
import CaddieSelectPopup from '@/views/game-progress/popup/CaddieSelectPopup';
import FindVisitPopup from '@/views/common/FindVisitPopup';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import GolfErpAPI from '@/api/v2/GolfErpAPI';
import {mapGetters} from 'vuex';
import ReportView from '@/components/common/report/ReportView';

export default {
  name: 'AddVocPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputTextarea,
    CaddieSelectPopup,
    FindVisitPopup,
    ReportView,
    ErpButton
  },
  data() {
    return {
      bsnDate: null,
      argmtId: null,
      checkInId: null,
      startTime: null,
      startCourse: null,
      vocInfo: {
        vocId: null,
        writngDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
        wrterName: null,
        visitName: null,
        vocSection: commonCodeGetComCodeDefaultValue('VOC_SECTION'),
        vocCode: commonCodeGetComCodeDefaultValue('VOC_CODE'),
        vocContents: null,
      },
      writeData: [],
      vocSectionOptions: commonCodesGetCommonCode('VOC_SECTION', true),
      vocCodeOptions: commonCodesGetCommonCode('VOC_CODE', true),
      fields: {text: 'comName', value: 'comCode'},
      courseHoleInfos: [],
      isCaddieSelectPopupOpen: false,
      isFindVisitPopupOpen: false,
      validateRefList: {
        writngDate: {required: true},
        wrterName: {required: true},
        visitName: {required: true},
        vocSection: {required: true},
        vocCode: {required: true},
        vocContents: {required: true},
      },
    };
  },
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return (
          this.isCaddieSelectPopupOpen ||
          this.isFindVisitPopupOpen
      );
    },
  },
  methods: {
    formPreventEnterEvent,
    validateFormRefs,
    commonCodesGetComName,
    async showPopup(bsnDate, argmtId, checkInId, startTime, startCourse) {
      this.bsnDate = bsnDate;
      this.argmtId = argmtId;
      this.checkInId = checkInId;
      this.startTime = startTime;
      this.startCourse = startCourse;
      await this.fetch();
    },
    closePopup() {
      this.$emit('popupClosed');
    },
    async onCaddieNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        if (args.value.trim() === '') {
          this.vocInfo.caddieId = null;
          return;
        }
        await this.openCaddieSelectPopup();
      }
    },
    async onMemberNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        if (args.value.trim() === '') {
          this.vocInfo.memberId = null;
          this.vocInfo.memberNo = null;
          this.vocInfo.memberDiv = null;
          return;
        }
        await this.openVisitFindPopup();
      }
    },
    async wrterNameInputKeyEnter() {
      if (!this.vocInfo.wrterName || this.vocInfo.wrterName === '') {
        return;
      }
      await this.openCaddieSelectPopup();
    },
    async visitNameInputKeyEnter() {
      if (!this.vocInfo.visitName || this.vocInfo.visitName === '') {
        return;
      }
      await this.openVisitFindPopup();
    },
    async openCaddieSelectPopup() {
      this.isCaddieSelectPopupOpen = true;
      await this.$nextTick();
      this.$refs.caddieSelectPopup.showPopup(this.vocInfo.wrterName, 'textField', null);
    },
    closeCaddieSelectPopup() {
      this.isCaddieSelectPopupOpen = false;
    },
    caddieSelectPopupConfirmed(data) {
      this.closeCaddieSelectPopup();
      if (data) {
        if (data.selectedRowData) {
          this.vocInfo.wrterName = data.selectedRowData.caddieName;
          this.vocInfo.caddieId = data.selectedRowData.caddieId;
        } else {
          this.vocInfo.caddieId = 0;
        }
      } else {
        this.vocInfo.caddieId = 0;
      }
    },
    async openVisitFindPopup() {
      this.isFindVisitPopupOpen = true;
      await this.$nextTick();
      await this.$refs.findVisitPopup.showFindVisitPopup({
        bsnDate: getFormattedDate(this.vocInfo.writngDate),
        resveVisitName: this.vocInfo.visitName,
        resveVisitFlag: false,
        isGetTeamData: true,
      });
    },
    onFindVisitPopupClosed(args) {
      this.isFindVisitPopupOpen = false;
      if (args && args.selectRowData && args.teamData) {
        this.vocInfo.visitName = args.selectRowData.visitName;
        this.vocInfo.visitId = args.selectRowData.visitId;
        this.vocInfo.memberId = args.selectRowData.memberId;
        this.vocInfo.memberNo = args.selectRowData.memberNo;
        this.vocInfo.memberDiv = args.selectRowData.memberDiv;
        this.vocInfo.startTime = args.selectRowData.startTime;
        this.vocInfo.startCourse = args.selectRowData.startCourse;
      } else {
        this.vocInfo.visitId = 0;
        this.vocInfo.memberId = '';
        this.vocInfo.memberNo = '';
        this.vocInfo.memberDiv = 'NOM';
        this.vocInfo.startTime = '';
        this.vocInfo.startCourse = '';
      }
    },
    getWriteData(visitId) {
      return this.writeData
          ?.filter(item => item.visitId === visitId);
    },
    onChangeVocContent(vocId, visitId, sortNo) {
      let findItem = this.writeData
          ?.find(item => item.vocId && item.vocId === vocId);
      if (!findItem) {
        findItem = this.writeData
            ?.find(item => item.visitId === visitId && item.sortNo === sortNo);
      }
      findItem.isModified = true;
    },
    onPrintClick() {
      const mapData = JSON.parse(JSON.stringify(this.writeData))
          ?.map(item => {
            item.vocSection = commonCodesGetComName('VOC_SECTION', item.vocSection);
            item.vocCode = commonCodesGetComName('VOC_CODE', item.vocCode);
            return item;
          });
      const reportData = {
        reportJson: {
          jsonData: mapData,
          writngDate: moment(this.writngDate).format('YY. M. D (ddd)'),
          teeoff: `${this.startTime} (${commonCodesGetComName('COURSE_CODE', this.startCourse)})`,
          caddieName: this.vocInfo.wrterName,
          username: this.username,
          uniqueCode: this.$options.name,
        },
      };
      this.$refs.reportViewComponent.postReport(reportData, 'VocCaddieStatus');
    },
    async onSaveClick() {
      const pureData = this.writeData?.filter(item => item.isModified);
      if (pureData.length < 1) {
        this.errorToast(this.$t('main.popupMessage.noDataToSave'));
        return;
      }
      let isSaved = false;
      try {
        let putData = [];
        pureData
            ?.filter(item => item.vocContents && item.vocContents.trim() !== '')
            ?.forEach(item => {
              putData.push({
                vocId: item.vocId,
                writngDate: this.vocInfo.writngDate,
                wrterName: this.vocInfo.wrterName,
                caddieId: this.vocInfo.caddieId,
                visitName: item.visitName,
                visitId: item.visitId,
                membershipId: item.memberId,
                memberNo: item.memberNo,
                memberDiv: item.memberDiv || 'NOM',
                vocSection: item.vocSection,
                vocCode: item.vocCode,
                vocContents: item.vocContents,
                gcDiv: 'G',
              });
            });
        if (putData.length > 0) {
          await GolfErpAPI.putVocInfoByTeam(putData);
          isSaved = true;
        }
        for (const data of pureData?.filter(item => item.vocId && item.vocContents.trim() === '')) {
          await GolfErpAPI.deleteVocInfo(data.vocId);
          isSaved = true;
        }
        if (!isSaved) {
          this.errorToast(this.$t('main.popupMessage.noDataToSave'));
        }
      } catch (e) {
        this.errorToast(this.$t('main.popupMessage.serverError'));
        return;
      }
      if (isSaved) {
        this.infoToast(this.$t('main.popupMessage.saved'));
        await this.fetch();
      }
    },
    async onClearClick(isAllClear = false) {
      this.vocInfo.vocId = null;
      if (isAllClear) {
        this.vocInfo.writngDate = moment().format(DATE_FORMAT_YYYY_MM_DD);
        this.vocInfo.visitName = null;
        this.vocInfo.visitId = null;
        this.vocInfo.memberId = null;
        this.vocInfo.memberNo = null;
        this.vocInfo.memberDiv = null;
        this.vocInfo.startTime = null;
        this.vocInfo.startCourse = null;
      }
      this.vocInfo.vocSection = commonCodeGetComCodeDefaultValue('VOC_SECTION');
      this.vocInfo.vocCode = commonCodeGetComCodeDefaultValue('VOC_CODE');
      this.vocInfo.vocContents = '';
      await this.$nextTick();
      isAllClear
          ? this.$refs.visitName.focusIn()
          : this.$refs.vocSection.focusIn();
    },
    async onDeleteClick(vocId) {
      if (!vocId || !(await this.confirm(this.$t('main.popupMessage.confirmDelete')))) {
        return;
      }
      try {
        const findItem = this.writeData
            ?.find(item => item.vocId === vocId);
        if (findItem) {
          await GolfErpAPI.deleteVocInfo(vocId);
          findItem.vocId = null;
          findItem.vocCode = commonCodeGetComCodeDefaultValue('VOC_CODE');
          findItem.vocContents = '';
          findItem.isModified = false;
          this.infoToast(this.$t('main.popupMessage.deleted'));
        } else {
          this.errorToast(this.$t('main.popupMessage.noDataToDelete'));
        }
      } catch (error) {
        this.errorToast(error);
      }
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },

    // API
    async fetch() {
      this.vocInfo = await GolfErpAPI.fetchVocInfoByTeam({
        argmtId: this.argmtId,
        chkinId: this.checkInId,
      });
      this.vocInfo.writngDate = this.bsnDate;
      this.vocInfo.wrterName = this.vocInfo.teamCaddie.caddie.name;
      this.vocInfo.caddieId = this.vocInfo.teamCaddie.caddieId;
      this.writeData = [];
      this.vocInfo?.visitors?.map(visitor => {
        visitor?.vocSections?.forEach(item => {
          if (item.vocMsts.length > 0) {
            item?.vocMsts?.forEach(mst => {
              this.writeData.push({
                visitName: visitor.name,
                visitId: visitor.id,
                memberId: visitor.membershipId,
                memberNo: visitor.memberNumber,
                memberDiv: visitor.memberDivision,
                vocId: mst.vocId || null,
                vocSection: item.vocSection || null,
                vocSectionName: item.vocSectionName || null,
                vocCode: mst.vocCode || null,
                vocContents: mst.vocContents || null,
                sortNo: item.sortNo,
                isModified: false,
              });
            });
          } else {
            this.writeData.push({
              visitName: visitor.name,
              visitId: visitor.id,
              memberId: visitor.membershipId,
              memberNo: visitor.memberNumber,
              memberDiv: visitor.memberDivision,
              vocId: null,
              vocSection: item.vocSection,
              vocSectionName: item.vocSectionName,
              vocCode: commonCodeGetComCodeDefaultValue('VOC_CODE'),
              vocContents: null,
              sortNo: item.sortNo,
              isModified: false,
            });
          }
        });
      });
    },
  },
};
</script>
